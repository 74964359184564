import React, { useEffect, useState } from "reactn";
import styled from "styled-components";

import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import { AppControllerState } from "js/core/AppController";
import TextInput from "./TextInput";
import { trackState } from "js/analytics";
import { trackActivity } from "js/core/utilities/utilities";
import getLogger, { LogGroup } from "js/core/logger";
import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";

const logger = getLogger(LogGroup.WORKSPACES);

const StyledTextInput = styled(TextInput)`
    position: relative;
`;

export const WorkspaceNameInput =
    WorkspaceController.withInitializedState(
        function WorkspaceNameInput(props: WorkspaceControllerState & AppControllerState & { disabled?: boolean }) {
            const { workspace, disabled = false } = props;

            const [workspaceName, setWorkspaceName] = useState(workspace.name);
            const [fetching, setFetching] = useState(false);

            useEffect(() => {
                setWorkspaceName(workspace.name);
            }, [workspace.name]);

            const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                setWorkspaceName(event.target.value);
            };

            const handleBlur = async () => {
                const trimmedWorkspaceName = workspaceName.trim();

                if (workspace.name === trimmedWorkspaceName) {
                    return;
                }

                if (trimmedWorkspaceName.length === 0) {
                    ShowErrorDialog({
                        title: "Error",
                        message: "Organization name cannot be empty"
                    });
                    setWorkspaceName(workspace.name);
                    return;
                }

                try {
                    setFetching(true);

                    await WorkspaceController.updateWorkspace({ name: trimmedWorkspaceName });

                    // Legacy analytics
                    const props = {
                        old_organization_name: workspace.name,
                        new_organization_name: trimmedWorkspaceName
                    };
                    trackState({ "organization_name": trimmedWorkspaceName });
                    trackActivity("Organization", "NameChanged", null, null, props, { audit: true });
                } catch (err) {
                    logger.error(err, "[WorkspaceNameInput] handleBlur() failed to update workspace name", { workspaceId: workspace.id });

                    setWorkspaceName(workspace.name);

                    ShowErrorDialog({
                        title: "Error",
                        message: "Failed to update organization name"
                    });
                } finally {
                    setFetching(false);
                }
            };

            return (
                // @ts-ignore
                <StyledTextInput
                    label="ORGANIZATION NAME"
                    id="workspace-name-input"
                    curValue={workspaceName}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={disabled || fetching}
                    maxLength={50}
                >
                    <FetchingClickShield visible={fetching} backgroundColor="white" />
                </StyledTextInput>
            );
        }
    );

