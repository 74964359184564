import { Icon as MaterialIcon } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { Button } from "js/Components/Button";
import { Input as InputRaw } from "js/Components/Input";
import { ToggleSwitch } from "js/Components/ToggleSwitch";
import { WithLabel as WithLabelRaw } from "js/Components/WithLabel";

export const SectionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 14px;
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
`;

export const TitleText = styled.div`
    font-weight: 600;
    text-transform: uppercase;
`;

export const Icon = styled(MaterialIcon) <{ blue?: boolean, green?: boolean, orange?: boolean, clickable?: boolean }>`
    &&& {
        font-size: 15px;
        margin-right: 2px;
        margin-bottom: -2px;
        color: ${({ blue, green, orange }) => blue ? "#11a9e2" : green ? "#008000" : orange ? "#ffa500" : undefined};
        cursor: ${({ clickable }) => clickable ? "pointer" : undefined};
`;

export const Text = styled.div<{ muted?: boolean, warning?: boolean }>`
    color: ${({ muted, warning }) => warning ? "#ff6d6d" : muted ? "#aaa" : "#000"};
`;

export const BlueButton = styled(props => <Button {...props} blue />)`
    width: 100%;
`;

export const OrangeButton = styled(props => <Button {...props} warning />)`
    width: 100%;
`;

export const InputAndButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px; 
    width: 100%;

    >button {
        height: 30px;
        width: auto;
        white-space: nowrap;
    }
`;

const WithLabelStyled = styled(WithLabelRaw)`
    .tooltip-badge-container {
        top: 0;
    }
`;

/**
 * Wrapper to avoid ts errors
 */
function WithLabel(props: {
    label: string,
    above?: boolean,
    left?: boolean,
    tooltip?: string,
    children: React.ReactNode
}) {
    // @ts-ignore
    return (<WithLabelStyled label={props.label} above={props.above} left={props.left} gap={5} toolTip={props.tooltip}>
        {props.children}
    </WithLabelStyled>);
}

function Input(props: {
    value: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    disabled?: boolean,
    ["data-test-id"]?: string
}) {
    return <InputRaw value={props.value} onChange={props.onChange} disabled={props.disabled} width="100%" data-test-id={props["data-test-id"]} />;
}

export function InputWithLabel(props: {
    value: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    label: string,
    tooltip?: string,
    disabled?: boolean,
    ["data-test-id"]?: string
}) {
    return (<WithLabel label={props.label} above tooltip={props.tooltip}>
        <Input
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            data-test-id={props["data-test-id"]}
        />
    </WithLabel>);
}

export function ToggleSwitchWithLabel(props: {
    label: string,
    tooltip?: string,
    value: boolean,
    onChange: (value: boolean) => void,
    disabled?: boolean,
    ["data-test-id"]?: string
}) {
    return (<WithLabel label={props.label} left tooltip={props.tooltip}>
        <ToggleSwitch value={props.value} onChange={props.onChange} disabled={props.disabled} data-test-id={props["data-test-id"]} />
    </WithLabel>);
}

export function InputAndButtonWithLabel(props: {
    label: string,
    tooltip?: string,
    value: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onClick: () => void,
    buttonText: string,
    inputDisabled?: boolean,
    buttonDisabled?: boolean,
    ["data-test-id"]?: string
}) {
    return (<WithLabel label={props.label} above tooltip={props.tooltip}>
        <InputAndButtonContainer>
            <Input
                value={props.value}
                onChange={props.onChange}
                disabled={props.inputDisabled}
                data-test-id={props["data-test-id"]}
            />
            <Button
                blue
                disabled={props.buttonDisabled}
                onClick={props.onClick}
                data-test-id={props["data-test-id"]}
            >
                {props.buttonText}
            </Button>
        </InputAndButtonContainer>
    </WithLabel>);
}
