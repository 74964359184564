import React, { Component } from "react";
import styled from "styled-components";

import { FeatureType } from "common/features";
import { Button } from "js/Components/Button";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import AppController, { AppControllerState } from "js/core/AppController";
import getLogger from "js/core/logger";
import { app } from "js/namespaces";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { UIPane, UIPaneContents, UIPaneHeader } from "js/react/components/UiComponents";

import { SSODialog } from "../dialogs/SSODialog";
import { SalesforceDialog } from "../dialogs/SalesforceDialog";

const logger = getLogger();

const CardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

const SectionCard = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fff;
    padding: 30px 20px;
    align-items: center;
`;

const SectionContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const SectionTitle = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
`;

const SectionDescription = styled.div`
    font-size: 14px;
    color: #666;
`;

type IntegrationsPaneProps = AppControllerState & WorkspaceControllerState;

class IntegrationsPane extends Component<IntegrationsPaneProps, {}> {
    handleSSOClick = () => {
        ShowDialog(SSODialog);
    }

    handleSalesforceClick = () => {
        ShowDialog(SalesforceDialog);
    }

    isPaneAvailable = pane => {
        const { workspaceId } = this.props;

        if (pane === "salesforce") {
            const hasSalesforceIntegration = app.user.features.isFeatureEnabled(FeatureType.SALESFORCE_INTEGRATION, workspaceId);
            // To be rewired to workspace group action permissions
            const { prohibitSalesforceIntegration } = AppController.currentTeam.get("workspaceSettings") || {};
            return hasSalesforceIntegration && !prohibitSalesforceIntegration;
        }

        return true;
    };

    render() {
        const { workspace } = this.props;

        const isSsoEnabled = workspace.sso.enabled;
        const isStrictSSOEnabled = workspace.sso.enabled && workspace.sso.strict;

        const isSalesforceEnabled = workspace.integrations.salesforce?.enabled;

        return (
            <UIPane>
                <UIPaneHeader>Integrations</UIPaneHeader>
                <UIPaneContents>
                    <CardsContainer>
                        {this.isPaneAvailable("sso") && (
                            <SectionCard>
                                <SectionContent>
                                    <SectionTitle>Single Sign-On</SectionTitle>
                                    <SectionDescription>
                                        {isSsoEnabled
                                            ? `SSO is enabled and ${isStrictSSOEnabled ? `required` : `optional`} for your organization.`
                                            : "SSO is not yet enabled for your organization."}
                                    </SectionDescription>
                                </SectionContent>
                                <Button blue onClick={this.handleSSOClick}>
                                    {isSsoEnabled ? "Edit Configuration" : "Enable SSO"}
                                </Button>
                            </SectionCard>
                        )}
                        {this.isPaneAvailable("salesforce") && (
                            <SectionCard>
                                <SectionContent>
                                    <SectionTitle>Salesforce</SectionTitle>
                                    <SectionDescription>
                                        {isSalesforceEnabled
                                            ? "Salesforce is enabled for your organization"
                                            : "Salesforce is not yet enabled for your organization"}
                                    </SectionDescription>
                                </SectionContent>
                                <Button blue={!isSalesforceEnabled} warning={isSalesforceEnabled} onClick={this.handleSalesforceClick}>
                                    {isSalesforceEnabled ? "Disconnect Salesforce" : "Enable Salesforce"}
                                </Button>
                            </SectionCard>
                        )}
                    </CardsContainer>
                </UIPaneContents>
            </UIPane >
        );
    }
}

export default AppController.withState(WorkspaceController.withInitializedState(IntegrationsPane));
