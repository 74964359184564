import { Icon } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { Component, useEffect, useRef } from "react";
import { Transition } from "react-transition-group";
import styled from "styled-components";

import {
    LibraryView,
    PresentationFilters
} from "common/constants";
import { FeatureType } from "common/features";
import { getStaticUrl } from "js/config";
import PresentationLibraryController from "js/controllers/PresentationLibraryController";
import AppController from "js/core/AppController";
import { ds } from "js/core/models/dataService";
import { Presentation } from "js/core/models/presentation";
import isConnected from "js/core/utilities/isConnected";
import { delay } from "js/core/utilities/promiseHelper";
import { trackActivity } from "js/core/utilities/utilities";
import { CreatePresentationDialogPaneType } from "js/editor/CreatePresentation/CreatePresentationDialogTypes";
import { PresentationLibraryPane } from "js/editor/PresentationLibrary/PresentationLibraryPane";
import { UserMenu } from "js/editor/components/UserMenu";
import { UIController } from "js/editor/dialogs/UIController";
import { app } from "js/namespaces";
import DesignerBotIcon from "js/react/components/DesignerBotIcon";
import {
    ShowDialog,
    ShowOfflineDialog
} from "js/react/components/Dialogs/BaseDialog";
import { ProvideAccessDialog, RequestAccessDialog } from "js/react/components/Dialogs/FolderAccessDialogs";
import { FlexSpacer, Gap10, Gap20 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import ProBadge from "js/react/components/ProBadge";
import {
    BlueButton,
    WhiteIconButton
} from "js/react/components/UiComponents";
import UpgradeButton from "js/react/components/UpgradeButton";
import { dialogTheme } from "js/react/materialThemeOverrides";
import { themeColors } from "js/react/sharedStyles";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";
import MyResources from "js/react/views/MyResources/MyResources";
import TeamResources from "js/react/views/TeamResources/TeamResources";
import { AddPresentationToFolderDialog } from "js/react/views/TeamResources/dialogs/TeamFolderDialogs";
import { PresentationLibraryControllerInitializer } from "js/editor/PresentationLibrary/PresentationLibraryControllerInitializer";
import { _ } from "js/vendor";
import { useMountedAt } from "js/core/utilities/useMountedAt";
import getLogger from "js/core/logger";
import { MetricName } from "common/interfaces/IMetric";

const logger = getLogger();

import "css/presentations.scss";

const PresentationLibraryContainer = styled.div`
    width: 100vw;
    height: 100vh;
`;

const LibraryHeaderContainer = styled.div`
    width: 100%;
    height: 50px;
    background: #333;
    padding: 0px;
    display: flex;
    align-items: center;
    color: white;

    .logo-desktop {
        height: 24px;
        cursor: pointer;
    }

    .logo-mobile {
        height: 20px;
        cursor: pointer;
    }

    .avatar {
        width: 30px;
        height: 30px;
    }

    input {
        color: white;
    }

    .MuiIcon-root {
        color: white;
    }

    .MuiSlider-rail {
        background: #aaa;
    }

    .MuiSlider-track {
        background: #aaa;
    }

    .empty-trash-header {
        display: flex;
        align-items: center;
        height: 100%;

        .MuiIcon-root {
            color: orangered;
        }

        label {
            height: 100%;
            background: orangered;
            color: white;
            display: block;
            display: inline-flex;
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
            padding: 0px 20px;
        }
    }
`;

const LogoBox = styled.div`
    background: #222;
    width: 260px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

const PopupMenuMask = styled.div`
    position: absolute;
    z-index: 2000;
`;

const CreatePopupMenu = styled.div`
    top: 5px;
    position: absolute;
    padding: 10px;
    background: white;
    border-radius: 2px;
    color: black;
    flex-direction: column;
    z-index: 1000;
    display: flex;
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.2);
    
    align-items: flex-start;
    align-items: center;
    opacity: ${({ state }) => (["entering", "entered"].includes(state) ? 1 : 0)};
    display: ${({ state }) => (["entering", "entered"].includes(state) ? "block" : "none")};;
    transition: 300ms;
`;

const CreatePopupMenuItem = styled.div`
    font-size: 14px;
    font-weight: 600;
    gap: 10px;
    white-space: nowrap;
    color: #222;
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    
    &:hover {
        background: #f1f1f1;
    }

    .MuiIcon-root {
        font-size: 18px;
        color: ${themeColors.mediumGray};
    }
`;

const StyledBlueButton = styled(BlueButton)`
    &&& {
        height: 100%;
        padding: 6px 20px;
        border-radius: 0;
    }
`;

const NotificationsLabelButton = styled(WhiteIconButton)`
    .MuiButtonBase-root {
        position: relative;
    }

    span.countLabel {
        position: absolute;
        color: white;
        background-color: ${themeColors.ui_blue};
        border-radius: 80px;
        min-width: 8px;
        height: 14px;
        font-weight: 600;
        top: 8px;
        left: 23px;
        padding: 1px 4px;
        letter-spacing: 0px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.small {
            font-size: 9px;
            line-height: 15px;
        }
    }
`;

const IntegratorMessage = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
    color: white;
`;

const NewDocumentButtonContainer = styled.div`
    height: 100%;
`;

class NotificationsLabel extends Component {
    constructor() {
        super();

        this.state = {
            unreadCount: 0
        };
    }

    setUnreadCount = () => {
        const workspaceId = UIController.getWorkspaceId();
        const unreadCount = AppController.notificationsService.unread.filter(notification => notification.event.workspaceId === workspaceId).length;
        this.setState({ unreadCount });
    }

    componentDidMount() {
        this.setUnreadCount();
        AppController.notificationsService.onUnreadChanged(this.setUnreadCount);
    }

    componentWillUnmount() {
        AppController.notificationsService.offUnreadChanged(this.setUnreadCount);
    }

    render() {
        const { onClick } = this.props;
        const { unreadCount } = this.state;

        return (
            <NotificationsLabelButton onClick={onClick}>
                <Icon>notifications</Icon>
                {unreadCount > 0 && <span
                    className={`countLabel ${unreadCount > 99 ? "small" : ""}`}>{unreadCount > 999 ? ">1k" : unreadCount}</span>}
            </NotificationsLabelButton>
        );
    }
}

class PresentationLibrary extends Component {
    constructor(props) {
        super(props);

        const librarySettings = app.user.getLibrarySettings();

        this.state = {
            showSidebar: false,
            sideBarWidth: librarySettings.sideBarWidth || 260,
        };

        if (!props.workspaceId) {
            throw new Error("workspaceId must be present");
        }

        this.debouncedSaveSideBarWidth = _.debounce(sideBarWidth => {
            app.user.update({
                librarySettings: { sideBarWidth }
            });
        }, 1000);
    }

    handleClickLogo = () => {
        UIController.gotoMarketingPage();
    }

    handleShowNotifications = () => {
        AppController.showAccountPane({ pane: "notifications" });
    }

    componentDidMount() {
        const { pane, subFolderId, folderId } = this.props;
        // If there's gDriveFolderId specified, then we've been redirected from GDrive
        // so we need to create a new presentation in the specified folder
        const gDriveFolderId = new URL(window.location).searchParams.get("gDriveFolderId");
        if (gDriveFolderId) {
            AppController.showCreatePresentationDialog({
                pane: CreatePresentationDialogPaneType.BLANK_PRESENTATION,
                state: {
                    libraryFilter: {
                        type: pane,
                        folderId,
                        subFolderId
                    }
                }
            });
            return;
        }
    }

    handleContextMenu = event => {
        event.preventDefault();
    }

    handleClickEarnCredit() {
        AppController.showAccountPane({ pane: "referrals" });
    }

    handleSwitchView = view => {
        switch (view) {
            case LibraryView.LIBRARY:
                AppController.showLibrary();
                break;
            case LibraryView.TEAM_RESOURCES:
                AppController.showTeamResources();
                trackActivity("TeamResources", "Viewed");
                break;
            case LibraryView.MY_RESOURCES:
                AppController.showMyResources();
                trackActivity("MyResources", "Viewed");
                break;
        }
    }

    handleResizeSideBar = width => {
        width = Math.clamp(width, 200, 400);
        this.setState({ sideBarWidth: width });
        this.debouncedSaveSideBarWidth(width);
    }

    handleMouseEnterNewDocumentButton = () => {
        this.setState({
            showCreateMenu: "on",
        });
    }

    handleMouseLeaveNewDocumentButton = async () => {
        this.setState({ showCreateMenu: "timeout" });
        await delay(600);
        if (this.state.showCreateMenu == "timeout") {
            this.setState({ showCreateMenu: null });
        }
    }

    handleCreateNewPresentation = pane => {
        //const { filter } = this.state;
        const { pane: sideBarPane, subFolderId, folderId } = this.props;

        const create = libraryFilter => {
            if (app.integrator) {
                // Open a new tab to the desktop presentation list
                window.open("/", "_blank");
            } else {
                //offline currently not supported as it just errors out when you try to create a presentation. Offline could fail at this point
                if (!isConnected.connected) {
                    ShowOfflineDialog();
                    return;
                }

                AppController.showCreatePresentationDialog({
                    pane,
                    state: {
                        libraryFilter
                    }
                });
            }
        };

        // If we are trying to create a presentation from the team folder
        if (sideBarPane === PresentationFilters.TEAM_FOLDER) {
            const defaultTeam = ds.teams.defaultTeamForOrg(AppController.orgId);
            const teamTheme = ds.sharedThemes.findWhere({ id: defaultTeam.get("sharedThemeId") });

            // Create a dummy presentation to pass to the dialog
            this.dummyPresentation = new Presentation({
                name: "Team Slide",
                isSlideEditor: true,
                userId: app.user.id,
                orgId: AppController.orgId,
                themeId: teamTheme.id,
                sharedThemeId: teamTheme.id,
                isDummy: true,
                _migrated: true
            }, { disconnected: true, userId: app.user.id });
            this.dummyPresentation.permissions.write = true;
            this.dummyPresentation.permissions.read = true;

            const folder = ds.teams.get(folderId);

            // hide the menu
            this.setState({
                showCreateMenu: null,
            });

            ShowDialog(AddPresentationToFolderDialog, {
                isDummy: true,
                folder,
                presentations: [this.dummyPresentation],
                callback: () => {
                    create({
                        type: sideBarPane,
                        subFolderId,
                        folderId
                    });
                }
            });

            return;
        }

        create({
            type: sideBarPane,
            subFolderId,
            folderId,
        });
    }

    render() {
        const { showSidebar, sideBarWidth, showCreateMenu } = this.state;
        const { workspaceId, pane, view = LibraryView.LIBRARY, onReady = () => { } } = this.props;

        const designerBotDisabled = app.user.features.isFeatureEnabled(FeatureType.PROHIBIT_GENERATIVE_AI, AppController.workspaceId) ||
            !app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_INSPIRATION_SLIDES, AppController.workspaceId);
        const designerBotAccessible = app.user.features.isFeatureEnabled(FeatureType.DESIGNER_BOT, AppController.workspaceId);
        const pptImportAccessible = app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_IMPORT_PPT, AppController.workspaceId);
        const starterTemplateAccessible = app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_INSPIRATION_SLIDES, AppController.workspaceId);

        const headerDesktop = (
            <LibraryHeaderContainer className="library-header">
                <LogoBox className="logo-box" onClick={this.handleClickLogo} style={{ width: sideBarWidth }}>
                    <img className="logo-desktop"
                        src={getStaticUrl("/images/beautifulai-logos/beautifulai-mark-reverse.svg")} />
                </LogoBox>

                <FlexBox fillWidth left fillHeight>
                    <NewDocumentButtonContainer
                        onMouseEnter={this.handleMouseEnterNewDocumentButton}
                        onMouseLeave={this.handleMouseLeaveNewDocumentButton}>
                        {view === LibraryView.LIBRARY && <StyledBlueButton
                            id="new-presentation-button"
                            onClick={() => this.handleCreateNewPresentation(CreatePresentationDialogPaneType.BLANK_PRESENTATION)}
                        >
                            <Icon>add_circle</Icon>Create presentation...
                        </StyledBlueButton>}
                        <Transition
                            in={!!showCreateMenu}
                            timeout={300}
                        >
                            {state => (
                                <PopupMenuMask state={state}>
                                    <CreatePopupMenu state={state}>
                                        <CreatePopupMenuItem
                                            onClick={() => this.handleCreateNewPresentation(CreatePresentationDialogPaneType.BLANK_PRESENTATION)}>
                                            <Icon>add_circle</Icon>Blank Presentation
                                        </CreatePopupMenuItem>
                                        {!designerBotDisabled &&
                                            <CreatePopupMenuItem onClick={() => this.handleCreateNewPresentation(CreatePresentationDialogPaneType.GENERATE_WITH_AI)} >
                                                <div>
                                                    <DesignerBotIcon style={{
                                                        width: 18,
                                                        height: 18,
                                                        fill: themeColors.mediumGray,
                                                    }} width={18} height={18} color={themeColors.mediumGray} />
                                                </div>
                                                Generate with AI
                                                <ProBadge
                                                    upgradeType={UpgradePlanDialogType.UPGRADE_PLAN}
                                                    show={!designerBotAccessible}
                                                    analytics={{ cta: "PresentationBot", ...ds.selection?.presentation?.getAnalytics() }}
                                                    workspaceId={AppController.workspaceId}
                                                />
                                            </CreatePopupMenuItem>
                                        }
                                        <CreatePopupMenuItem onClick={() => this.handleCreateNewPresentation(CreatePresentationDialogPaneType.TEAM_TEMPLATES)}>
                                            <Icon>video_library</Icon>From Team Template
                                            <ProBadge
                                                upgradeType={UpgradePlanDialogType.TEAM_NOOP}
                                                show={!app.user.features.isFeatureEnabled(FeatureType.VIEW_LIBRARY_ITEMS, AppController.workspaceId)}
                                                analytics={{ cta: "TeamTemplates" }}
                                                workspaceId={AppController.workspaceId}
                                            />
                                        </CreatePopupMenuItem>
                                        {starterTemplateAccessible &&
                                            <CreatePopupMenuItem
                                                onClick={() => this.handleCreateNewPresentation(CreatePresentationDialogPaneType.STARTER_TEMPLATES)}>
                                                <Icon>lightbulb</Icon>From Starter Template
                                            </CreatePopupMenuItem>
                                        }
                                        {pptImportAccessible && (
                                            <CreatePopupMenuItem
                                                onClick={() => this.handleCreateNewPresentation(CreatePresentationDialogPaneType.IMPORT_PPT)}>
                                                <Icon>upload</Icon>Import PPT
                                            </CreatePopupMenuItem>
                                        )}
                                    </CreatePopupMenu>
                                </PopupMenuMask>
                            )}
                        </Transition>
                    </NewDocumentButtonContainer>
                    <FlexSpacer />
                    <FlexBox fillHeight middle right style={{ paddingRight: 20 }}>
                        <Gap20 />
                        {app.user.features.isFeatureEnabled(FeatureType.UPGRADE, workspaceId) && (
                            <UpgradeButton workspaceId={workspaceId} />
                        )}
                        <NotificationsLabel onClick={this.handleShowNotifications} />
                        <Gap10 />
                        <UserMenu canSwitchWorkspace />
                    </FlexBox>
                </FlexBox>
            </LibraryHeaderContainer>
        );
        const menuIcon = (showSidebar
            ? "navigate_before"
            : "menu"
        );
        const headerMobile = (
            <LibraryHeaderContainer className="library-header">
                <FlexBox
                    fillHeight
                    fillWidth
                    horizontalAlign="between"
                    style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                    }}
                >
                    <FlexBox fillHeight middle onClick={() => {
                        this.setState({ showSidebar: !showSidebar });
                    }}>
                        <i className="micon">{menuIcon}</i>
                    </FlexBox>

                    {
                        !app.integrator &&
                        <LogoBox
                            className="logo-box"
                            onClick={this.handleClickLogo}
                        >
                            <img className="logo-mobile"
                                src={getStaticUrl("/images/beautifulai-logos/beautifulai-logo-reverse.svg")} />
                        </LogoBox>
                    }
                    {
                        app.integrator &&
                        <IntegratorMessage>Select a presentation to embed or create a new one.</IntegratorMessage>
                    }

                    <UserMenu canSwitchWorkspace />
                </FlexBox>
            </LibraryHeaderContainer>
        );
        const header = app.isConstrained ? headerMobile : headerDesktop;
        return (
            <ThemeProvider theme={dialogTheme}>
                <PresentationLibraryContainer onContextMenu={this.handleContextMenu}>
                    {header}
                    {view === LibraryView.LIBRARY &&
                        <PresentationLibraryPane
                            onSwitchType={this.handleSwitchView}
                            showSidebar={showSidebar}
                            sideBarWidth={sideBarWidth}
                            onResizeSidebar={this.handleResizeSideBar}
                            onHideSidebar={() => this.setState({ showSidebar: false })}
                            onReady={() => onReady(view, null)}
                        />
                    }
                    {view === LibraryView.TEAM_RESOURCES &&
                        <TeamResources
                            sideBarWidth={sideBarWidth}
                            pane={pane}
                            onSwitchType={this.handleSwitchView}
                            onResizeSidebar={this.handleResizeSideBar}
                            onReady={() => onReady(view, pane)}
                        />
                    }
                    {view === LibraryView.MY_RESOURCES &&
                        <MyResources
                            sideBarWidth={sideBarWidth}
                            pane={pane}
                            onSwitchType={this.handleSwitchView}
                            onResizeSidebar={this.handleResizeSideBar}
                            onReady={() => onReady(view, pane)}
                        />
                    }
                </PresentationLibraryContainer>
            </ThemeProvider>
        );
    }
}

// Report only once per user session
let reportedReady = false;

export default AppController.withState(
    PresentationLibraryController.withState(
        withFirebaseUser(
            React.forwardRef(
                function PresentationLibraryWrapper(props, ref) {
                    const {
                        teamMemberId,
                        provideAccess,
                        user,
                        workspaceId,
                        view = LibraryView.LIBRARY,
                        pane,
                        folderId,
                        subFolderId
                    } = props;

                    const mountedAt = useMountedAt();

                    useEffect(() => {
                        if (pane === PresentationFilters.TEAM_FOLDER) {
                            const defaultTeam = ds.teams.defaultTeamForOrg(AppController.orgId);
                            const teamFolder = ds.teams.get(folderId);

                            // check if the user is member of the team but doesn't have access to the teamFolder
                            if (defaultTeam.get("members")[app.user.id] && !teamFolder) {
                                ShowDialog(RequestAccessDialog, { folderId });
                            }
                        }
                    }, [pane, folderId]);

                    useEffect(() => {
                        if (provideAccess && teamMemberId) {
                            ShowDialog(ProvideAccessDialog, { folderId, teamMemberId });
                        }
                    }, [teamMemberId, provideAccess]);

                    const handleReady = (view, pane) => {
                        if (reportedReady) {
                            return;
                        }
                        reportedReady = true;

                        logger.metric(MetricName.LIBRARY_LOAD_TIME, {
                            loadTimeMs: Date.now() - mountedAt,
                            view: view ?? "",
                            pane: pane ?? ""
                        });
                    };

                    return (
                        <PresentationLibraryControllerInitializer
                            workspaceId={workspaceId}
                            user={user}
                            view={view}
                            pane={pane}
                            folderId={folderId}
                            subFolderId={subFolderId}
                        >
                            <PresentationLibrary {...props} onReady={handleReady} ref={ref} />
                        </PresentationLibraryControllerInitializer>
                    );
                }
            )
        )
    )
);
